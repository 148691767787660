import Cookies from 'js-cookie';
require('trix');
(function () {
  'use strict';

  window.addEventListener(
    'turbo:load',
    function () {
      $('.sidenav-toggler').on('click', function (e) {
        if ($('.sidenav-toggler').attr('class').includes('active')) {
          pinSidenav();
        } else {
          unpinSidenav();
        }
      });
      // Set sidenav state from cookie
      var $sidenavState = Cookies.get('sidenav-state')
        ? Cookies.get('sidenav-state')
        : 'pinned';

      if ($(window).width() > 1200) {
        if ($sidenavState == 'pinned') {
          pinSidenav();
        } else {
          unpinSidenav();
        }

        //if(Cookies.get('sidenav-state') == 'unpinned') {
        //    unpinSidenav()
        //}

        $(window).resize(function () {
          if (
            $('body').hasClass('g-sidenav-show') &&
            !$('body').hasClass('g-sidenav-pinned')
          ) {
            $('body')
              .removeClass('g-sidenav-show')
              .addClass('g-sidenav-hidden');
          }
        });
      }

      if ($(window).width() < 1200) {
        $('body').removeClass('g-sidenav-hide').addClass('g-sidenav-hidden');
        $('body').removeClass('g-sidenav-show');
        $(window).resize(function () {
          if (
            $('body').hasClass('g-sidenav-show') &&
            !$('body').hasClass('g-sidenav-pinned')
          ) {
            $('body')
              .removeClass('g-sidenav-show')
              .addClass('g-sidenav-hidden');
          }
        });
      }
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          'submit',
          function (event) {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
            jQuery(form)
              .find('select.select2-hidden-accessible:invalid, input.custom-checkbox:invalid')
              .each(function () {
                jQuery(this).parents('.form-group').eq(0).addClass('invalid');
              });
            // expand & display all custom fields that have validation error
            jQuery(form)
              .find('.custom_field_category_header')
              .each(function () {
                if (
                  jQuery(this).find(
                    'input:invalid, select:invalid, textarea:invalid'
                  ).length > 0
                ) {
                  jQuery(this).find('a').trigger('click');
                }
              });
          },
          false
        );
      });

      $('.rich_content a').on('click', function (e) {
        $(this).attr('target', '_blank');
        $(this).attr('rel', 'noopener noreferrer');
        return confirm(
          'WARNING: The link you clicked will take you to ' +
            $(this).attr('href') +
            " Click 'Cancel' if you do not trust this website"
        );
      });

      var DataTable = $.fn.dataTable;
      if (typeof DataTable != 'undefined') {
        $.extend(true, DataTable.Buttons.defaults, {
          dom: {
            container: {
              className: 'dt-buttons flex-wrap',
            },
            button: {
              className: 'btn btn-sm btn-neutral border',
            },
          },
        });

        var decode = function (str) {
          let txt = document.createElement('textarea');

          txt.innerHTML = str;

          return txt.value;
        };

        window.displayTimeInWord = (a) => {
          if (a <= 60) {
            return `${a} minutes`;
          } else if (a > 60 && a <= 1440) {
            var hours = Math.trunc(a / 60);
            var minutes = a % 60;
            return `${hours} hours ${minutes} minutes`;
          } else {
            var days = Math.trunc(a / 1440);
            var text = `${days} days `;

            var hours = Math.trunc((a % 1440) / 60);
            if (hours) {
              text = text.concat(`${hours} hours `);
            }
            var minutes = Math.trunc((a % 1440) % 60);
            if (minutes) {
              text = text.concat(`${minutes} minutes`);
            }
            return text;
          }
        };

        window.datatableOptions = {
          dom: '<"row"<"col-md-6 col-sm-12"B><"col-md-6 col-sm-12"f>>t<"row mb-2"<"col-md-3 col-sm-12"i><"col-md-3 col-sm-12"l><"col-md-6 col-sm-12"p>>',
          lengthChange: true,
          lengthMenu: [10, 25],
          pageLength: 10,
          buttons: [
            {
              extend: 'excel',
              exportOptions: {
                columns: ':visible:not(.notForExport)',
                // format: {
                //  body: function ( data, row, column, node ) {
                //    if (node.classList.contains('date_field')){
                //      // return Date.parse(data);
                //      switch(jQuery("#date").val()){
                //        case 'DD/MM/YYYY':
                //          var spli = data.split('/');
                //          var engl = spli[2] + '/' + spli[1] + '/' + spli[0];
                //          return  Math.round(25569 + (Date.parse(engl) / (86400 * 1000)));
                //        break;
                //        case 'MM/DD/YYYY':
                //          var spli = data.split('/');
                //          var engl = spli[2] + '/' + spli[0] + '/' + spli[1];
                //          return  Math.round(25569 + (Date.parse(engl) / (86400 * 1000)));
                //        break;
                //        default:
                //          return data;
                //      }
                //    } else {
                //      return decode(data);
                //    }
                //  }
                // }
              },
              // customize: function( xlsx, data, api) {
              //  var sheet = xlsx.xl.worksheets['sheet1.xml'];
              //  // Styling Date - using Style 67 for dates
              //  api.context[0].aoColumns.forEach(function(ele, index){
              //    if (ele.className == 'date_field'){
              //      $(`row c[r^="${String.fromCharCode(65 + index)}"]`, sheet).attr( 's', 67 );
              //    }
              //  })
              // }
            },
            {
              extend: 'pdf',
              exportOptions: {
                columns: ':visible:not(.notForExport)',
              },
            },
          ],
          pagingType: 'numbers',
          stateSave: true,
          stateSaveCallback: function (settings, data) {
            //localStorage.setItem( 'DataTables_' + settings.sInstance, JSON.stringify(data) )
            var keyName = 'datatables_config';
            try {
              keyName =
                'datatables_config_' + settings.nTable.dataset.tableName;
            } catch (e) {}
            localStorage.setItem(keyName, JSON.stringify(data));
          },
          stateLoadCallback: function (settings) {
            var keyName = 'datatables_config';
            try {
              keyName =
                'datatables_config_' + settings.nTable.dataset.tableName;
            } catch (e) {}
            var data = JSON.parse(localStorage.getItem(keyName));
            if (data) {
              delete data['search'];
            }
            return data;
          },

          initComplete: function (setting, json) {
            //  only First Time needs to load data for URL
            if (setting.iDraw > 1 ){
              return
            };
            if (
              setting.oInit.buttons.filter(function (b) {
                return b === 'filter';
              }).length
            ) {
              var _filter = setting._buttons[0].inst.s.buttons.find(function (
                a
              ) {
                return (a.conf.className || '').includes('buttons-filter');
              });
              _filter.conf.filter_data = JSON.parse(
                setting.nTable.dataset.staticData || '{}'
              ).filter_data;
              _filter.conf._createHtml.addedFilterCount(_filter.conf);
              _filter.conf._collection = jQuery(
                _filter.conf._createHtml.filterStructure(_filter.conf)
              );
            }
          },
        };
        window.serverSideOptions = {
          search: { regex: true },
          stateSave: true,
          processing: true,
          serverSide: true,
          columns: [],
        };
        if ($('#dt').data('sort') != undefined) {
          window.datatableOptions['order'] = [$('#dt').data('sort').split(':')];
        }
        var table = $('#dt').DataTable(window.datatableOptions);

        table.buttons().container().appendTo('#dt_wrapper .col-md-6:eq(0)');

        document.addEventListener('turbo:before-cache', function () {
          try {
            table.destroy();
            $('select.select2-hidden-accessible.not(.dont-destroy)').select2(
              'destroy'
            );
          } catch (e) {}
        });
      }

      const loadPopover = () => {
        $('[data-toggle="popover"]').popover();

        $('body').on('click', function (e) {
          $('[data-toggle=popover]').each(function () {
            // hide any open popovers when the anywhere else in the body is clicked
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
              $(this).popover('hide');
            }
          });
        });
      }

      // Load popover for the application
      loadPopover();

      //Load poover for the turbo frame
      document.addEventListener('turbo:frame-load', function() {
        loadPopover();
      });

      $('.collapse')
        .on('show.bs.collapse', function () {
          //$(this).parent().find(".accordian-icon").removeClass("fa-chevron-right").addClass("fa-chevron-down");
          //$(this).parent().find(".accordian-icon").css('transform', 'rotateZ(90deg)');
          var element = event.target;
          if (event.target.dataset.toggle != 'collapse') {
            element = jQuery(event.target)
              .parents('[data-toggle="collapse"]')
              .eq(0);
          }
          jQuery(element).find('.accordian-icon').addClass('down');
        })
        .on('hide.bs.collapse', function () {
          //$(this).parent().find(".accordian-icon").css('transform', null);
          var element = event.target;
          if (event.target.dataset.toggle != 'collapse') {
            element = jQuery(event.target)
              .parents('[data-toggle="collapse"]')
              .eq(0);
          }
          jQuery(element).find('.accordian-icon').removeClass('down');
          //$(this).parent().find(".accordian-icon").removeClass("fa-chevron-down").addClass("fa-chevron-right");
        });

      $('.sl').each(function (index, ele) {
        var param = {
          allowClear: true,
          placeholder: 'Click to select',
          include_hidden: false,
          tags: $(ele).data('custom'),
        };
        if ($(ele).attr('multiple')) {
          param['closeOnSelect'] = false;
        }
        $(ele).select2(param);
      });

      $('.custom-sl').select2({
        allowClear: true,
        placeholder: 'Click to select',
        include_hidden: false,
        tags: true,
      });

      $('.select2-selection').addClass('text-truncate');

      $("a[data-clipboard='true']").on('click', function () {
        this.focus();
        let url = $(this).attr('data-link');
        navigator.clipboard
          .writeText(url)
          .then(() => {
            alert('Link copied successfully');
          })
          .catch((error) => {
            window.open(url, '_blank');
          });
      });
      // Form Fields Focus
      var $input = $('.form-control');
      function initFocus($this) {
        $this
          .on('focus blur', function (e) {
            $(this)
              .parents('.form-group')
              .toggleClass('focused', e.type === 'focus');
          })
          .trigger('blur');
      }
      // Events
      if ($input.length) {
        initFocus($input);
      }

      $('body').on('click', '[data-action]', function (e) {
        e.preventDefault();
        var $this = $(this);
        var action = $this.data('action');
        var target = $this.data('target');

        // Manage actions

        switch (action) {
          case 'sidenav-pin':
            pinSidenav();
            break;

          case 'sidenav-unpin':
            unpinSidenav();
            break;

          case 'search-show':
            target = $this.data('target');
            $('body')
              .removeClass('g-navbar-search-show')
              .addClass('g-navbar-search-showing');

            setTimeout(function () {
              $('body')
                .removeClass('g-navbar-search-showing')
                .addClass('g-navbar-search-show');
            }, 150);

            setTimeout(function () {
              $('body').addClass('g-navbar-search-shown');
            }, 300);
            break;

          case 'search-close':
            target = $this.data('target');
            $('body').removeClass('g-navbar-search-shown');

            setTimeout(function () {
              $('body')
                .removeClass('g-navbar-search-show')
                .addClass('g-navbar-search-hiding');
            }, 150);

            setTimeout(function () {
              $('body')
                .removeClass('g-navbar-search-hiding')
                .addClass('g-navbar-search-hidden');
            }, 300);

            setTimeout(function () {
              $('body').removeClass('g-navbar-search-hidden');
            }, 500);
            break;
        }
      });
      $('.sidenav').on('mouseenter', function () {
        if (!$('body').hasClass('g-sidenav-pinned')) {
          $('body')
            .removeClass('g-sidenav-hide')
            .removeClass('g-sidenav-hidden')
            .addClass('g-sidenav-show');
        }
      });

      $('.sidenav').on('mouseleave', function () {
        if (!$('body').hasClass('g-sidenav-pinned')) {
          $('body').removeClass('g-sidenav-show').addClass('g-sidenav-hide');

          setTimeout(function () {
            $('body')
              .removeClass('g-sidenav-hide')
              .addClass('g-sidenav-hidden');
          }, 300);
        }
      });

      // Make the body full screen size if it has not enough content inside
      $(window).on('load resize', function () {
        if ($('body').height() < 800) {
          $('body').css('min-height', '100vh');
          $('#footer-main').addClass('footer-auto-bottom');
        }
      });

      // Added Default Back URL with history.
      $('a.back-btn-with-default').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (history.length > 1 && document.referrer != '') {
          return history.back();
        } else {
          window.location.href =
            $(e.currentTarget).data('url') || $(e.currentTarget).attr('href');
        }
        return false;
      });

      $('[data-read="more"]').each(function () {
        if ($(this).height() < 100) {
          $(this).next().remove();
        }
      });

      $('#noti_dropdown').on('hide.bs.dropdown', function (e) {
        var target = $(e.clickEvent.target);
        if (target.hasClass('keepopen') || target.parents('.keepopen').length) {
          return false; // returning false should stop the dropdown from hiding.
        } else {
          return true;
        }
      });
    },
    false
  );

  $(document).on('click', '.linkCopyToClipboard', function (event) {
    event.preventDefault();
    event.stopPropagation();
    var SELF = this;
    $(SELF).popover('hide');
    var $temp = $("<input>");
    $("body").append($temp);
    if(SELF.href){
      $temp.val(SELF.href.split("?")[0]).select();
    }else{
      $temp.val(SELF.getAttribute('href')).select();
    }
    document.execCommand("copy");
    $temp.remove();
    $(SELF).popover('show');
    setTimeout(function () {
      $(SELF).popover('hide');
    }, 3000);
  });

  Trix.default.config.textAttributes.underline = {
    style: { textDecoration: 'underline' },
    parser: function (element) {
      return element.textDecoration === 'underline';
    },
    inheritable: true,
  };

  const setNotAllowedCursor = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'none';
    document.querySelector('trix-editor').style.cursor = 'not-allowed';
  };

  const resetCursor = (e) => {
    e.preventDefault();
    document.querySelector('trix-editor').style.cursor = 'default';
  };

  addEventListener('trix-initialize', function (event) {
    const trixEditor = event.target;
    var toolbarElement = event.target.toolbarElement;
    var trix_button_group__text_tools = toolbarElement.querySelector(
      '.trix-button-group--text-tools'
    );
    var bulletButton = toolbarElement.querySelector(
      '[data-trix-attribute=bullet]'
    );
    bulletButton.setAttribute('data-trix-key', 'l');
    if (!trix_button_group__text_tools.querySelector('.underline')) {
      var buttonHTML =
        '<button type="button" class="underline trix-button" data-trix-attribute="underline" data-trix-action="underline" data-trix-key="u" title="underline" tabindex="-1">U</button>';
      trix_button_group__text_tools.insertAdjacentHTML('beforeend', buttonHTML);
    }
    trixEditor.addEventListener('dragenter', setNotAllowedCursor);
    trixEditor.addEventListener('dragover', setNotAllowedCursor);

    trixEditor.addEventListener('dragleave', resetCursor);
    trixEditor.addEventListener('dragend', resetCursor);
  });

  // Defined this fucntion to replace the select options
  $.fn.replaceOptions = function(options) {
    var self, $option;

    this.empty();
    self = this;

    $.each(options, function(index, option) {
      $option = $("<option></option>")
        .attr("value", option.value)
        .text(option.text);
      self.append($option);
    });
  };
})();

export function pinSidenav() {
  $('.sidenav-toggler').addClass('active');
  $('.sidenav-toggler').data('action', 'sidenav-unpin');
  $('body')
    .removeClass('g-sidenav-hidden')
    .addClass('g-sidenav-show g-sidenav-pinned');
  $('body').append(
    '<div class="backdrop d-xl-none" data-action="sidenav-unpin" data-target=' +
      $('#sidenav-main').data('target') +
      ' />'
  );

  // Store the sidenav state in a cookie session
  Cookies.set('sidenav-state', 'pinned');
}

export function unpinSidenav() {
  $('.sidenav-toggler').removeClass('active');
  $('.sidenav-toggler').data('action', 'sidenav-pin');
  $('body').removeClass('g-sidenav-pinned').addClass('g-sidenav-hidden');
  $('body').find('.backdrop').remove();

  // Store the sidenav state in a cookie session
  Cookies.set('sidenav-state', 'unpinned');
}
